"use strict";

/*
* Page - Accueil
* - preload images jumbotron change background-image of jumbotrom everytime page refresh
*/

var imageDescktop = [
  '../img/header/ronde.jpg',
  '../img/header/construction.jpg',
  '../img/header/plongeur.jpg',
  '../img/header/hiver.jpg',
  '../img/header/femmes.jpg',
  '../img/header/coureurs.jpg'
];
var imageMobile = [
  '../img/header/mobile-ronde.jpg',
  '../img/header/mobile-construction.jpg',
  '../img/header/mobile-plongeur.jpg',
  '../img/header/mobile-hiver.jpg',
  '../img/header/mobile-femmes.jpg',
  '../img/header/mobile-coureurs.jpg'
];

var backgroundContainer = document.getElementsByClassName("randomImage");

function getRandomImage(imagesArray) {
  return randomIndex = Math.floor(Math.random() * imagesArray.length);
}

function loadImage(imgUrl) {
  let img = new Image();
  img.onload = function() {
    setImage(img);
  };
  img.src = imgUrl;
}

function setImage(img) {
  $('.home-page .randomImage').css({'background-image': 'url(' + img.src + ')'});
  // whitch image is display by random function for add a class to jumbotrom
  let bgurls = $('.home-page .randomImage').css('background-image');
  if(bgurls) {
    let simpleurl = bgurls.replace(/(?:^url\(["']?|["']?\)$)/g, ""); // enleve le url
    let partsurl = simpleurl.split("/");
    let imgloaded = partsurl[partsurl.length-1]; // => ex: construction.jpg
    let titlepartsurl = imgloaded.split(".");
    let nameimage = titlepartsurl[0];
  
    $('.home-page .randomImage').addClass('img-' + nameimage + '');
    
  }
  
}
/* 
* Pages secondaire - trapez red
*/
function trapezRed() {
  let navBrand= $('.navbar-brand');
  let navPos =  navBrand.offset();// this will return the left and top
  let leftMargin  = navPos.left; // this will return left 
  let jumbotron=$('.trapez-red');
  let jumbotronHeight=jumbotron.innerHeight();
  let navbar = $('.navbar');
  let navbarHeight = navbar.outerHeight(true);

  let redWidth = 67;
  let marginR = redWidth/2;
  let marginTop = 0;
 
  if ($(window).width()<1500) { 
    marginR = marginR+3;
    
  }
  if ($(window).width() < 1200) {
     redWidth = 60;
  }
  if ($(window).width() < 768) {
    redWidth = 67;
  }

  if ($(window).width() < 568) {
    redWidth = 80;
    marginR = redWidth;
    jumbotronHeight = (jumbotronHeight-navbarHeight);
    marginTop = navbarHeight;
  }
  // if ($(window).width() < 415) {
  //   redWidth = 80;
  //   marginR = redWidth;
  // }
  
  $('.content-overlay').css('top',  marginTop +"px");
  $('.content-overlay').css('width',  redWidth + 'vw');
  $('.content-overlay').css('height',  jumbotronHeight + 'px');
  $('.content-overlay').css('border-right', marginR + 'vw solid transparent');
  $('.content-overlay').css('border-bottom', jumbotronHeight+'px solid #820000');

}

/*
	*  Page - Event
  */
function eventHeader() {
  let navbar = $('.navbar');
  let navbarHeight = navbar.outerHeight(true);
  let marginTop = navbarHeight + 40;

  $('.jumbotron-content').css('margin-top',  marginTop +"px");
}

function eventColRight() {
  let btn = $('.booking .btn');
  let btnWidth = btn.outerWidth(true);
  let noticeInfo = $('.notice-info');
  let noticeWidth = btnWidth;
  
  noticeInfo.css('width',  noticeWidth + "px");
}

 /*
	*  Page loader
  */
  document.onreadystatechange = function() {
    if (document.readyState !== "complete") {
        document.querySelector(
          "body").style.visibility = "hidden";
        document.querySelector(
          ".preloader").style.visibility = "visible";
    } else {
        document.querySelector(
          ".preloader").style.display = "none";
        document.querySelector(
          "body").style.visibility = "visible";
    }
  };


(function($) {
  
  /*
	*  Navigation
  */

  $(window).on('scroll', function() {
    let navbarheight = $(".navbar").height();
    $('nav').toggleClass('nav-color', $(this).scrollTop() > navbarheight);
  });

  //add class to body on mobile when menu open for stop scrooling
  $('.navbar').on('shown.bs.collapse', function () {
  $('body').addClass('mobile-menu-open');
  })
  $('.navbar').on('hidden.bs.collapse', function () {
  $('body').removeClass('mobile-menu-open');
  })

  
  /*
  * Page - Accueil
  */
  let isHomePage = $('body').hasClass('home-page') ? true : false;

  window.onload = function() {
    
   // Change background-image of jumbotrom everytime page refresh
    if(isHomePage) {
    
      if ($(window).width() > 768) {	
        loadImage(imageDescktop[getRandomImage(imageDescktop)], backgroundContainer);
      } else {
        loadImage(imageMobile[getRandomImage(imageMobile)], backgroundContainer);
      }
    

      /* Carousel concours*/ 
      $('.carousel-concours .item').on('mouseleave', function() {
        $('.carousel-concours .slick-list').removeClass("overflow-visible");
      });

      $('.carousel-concours .item').on('mouseenter', function() {
        $('.carousel-concours .slick-list').addClass("overflow-visible");
      });

      //Carousel concours config
      $('.carousel-concours').slick({
        dots: false,
        infinite: true,
        speed: 300,
        slidesToShow: 5,
        slidesToScroll: 1,
        cssEase: 'linear',
        easing: 'linear',
        centerMode: true,
        draggable: false,
        useTransform: false,
        swipe: true,
        centerPadding: '10px',
        variableWidth: true,
        nextArrow: '<div class="fleche-right"></div><div class="fleche-overlay-right"></div>',
        prevArrow: '<div class="fleche-left"></div><div class="fleche-overlay-left"></div>',
        responsive: [
            {
              breakpoint: 1900,
              settings: {
                slidesToShow: 5,
                slidesToScroll: 1,
                infinite: true,
                dots: false
              }
            },
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 3,
                infinite: true,
                dots: false
              }
            },
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 2
              }
            },
            {
              breakpoint: 769,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1
              }
            }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
        ]
      });
      

      /* Carousel memoire */ 

      // add/remove class .overflow-visible for diplay image
      $('#memoire-carousel .carousel-item').on('mouseenter', function() {
        $('#memoire-carousel .carousel-inner').addClass("overflow-visible");
      })
      $('#memoire-carousel .carousel-item').on('mouseleave', function() {
        $('#memoire-carousel .carousel-inner').removeClass("overflow-visible");
      })

      // add/remove class .visible-mobile 
      // $('#memoire-carousel .carousel-control-next').on('click', function() {
      //   $('#memoire-carousel .carousel-control-prev').addClass("visible-mobile");
      // })

      // $('#memoire-carousel').on('touchstart', function(){
      //   $('#memoire-carousel .carousel-control-prev').addClass("visible-mobile");
      // })

      // $('#memoire-carousel').on('touchmove', function(){
      //   $('#memoire-carousel .carousel-control-prev').removeClass("visible-mobile");
      // })

      $('#memoire-carousel').on('slide.bs.carousel', function (e) {
        let $e = $(e.relatedTarget);
        let index = $e.index();
        let total_items = $('#memoire-carousel .carousel-item').length;
        let items_per_slide = 3;
        if (index >= total_items - (items_per_slide - 1)) {
          let it = items_per_slide - (total_items - index);
          for (let i = 0; i < it; i++) {
            if (e.direction == 'left') {
              $('#memoire-carousel').find('.carousel-item').eq(0).appendTo('#memoire-carousel .carousel-inner');
            //   console.log('left');
            } 
            else if (e.direction == 'right') {
              $('#memoire-carousel').find('.carousel-item').eq(0).appendTo('#memoire-carousel .carousel-inner');
            //   console.log('right');
            }
          }
        }
      })
    }
  };

  if(!isHomePage) {
    trapezRed();
  
    $(window).on("resize", function(){
      trapezRed();
    });
    $(window).on("load", function(){
      trapezRed();
    });

  }
   /*
  * Page - Event and ActivEduc sous page, Location de salles
  */
  let isEventPage = $('body').hasClass('event') ? true : false;
  let isActivEducSousPage = $('body').hasClass('activeduc') ? true : false;
  let isWinnersPage = $('body').hasClass('winners') ? true : false;
  let isLocationPage = $('body').hasClass('location') ? true : false;
  
  if(isEventPage || isActivEducSousPage || isWinnersPage || isLocationPage) {
    eventHeader();
    eventColRight();
    $(window).on("resize", function(){
      eventHeader();
      eventColRight();
    });

    $(window).on("load", function(){
      eventHeader();
      eventColRight();
    });
  }


  /*
  * Page Concours
  */
 
  var isConcoursPage = $('body').hasClass('concours-page') ? true : false;
  if(isConcoursPage) {
    if($('.nav-concours').length){
        let navMenu=$('.nav-concours');
        let navMenuHeight = navMenu.outerHeight(true);
        let fixedMenu = $("#main-navbar");
        let fixedMenuHeight = fixedMenu.outerHeight(true);
        let navTop = navMenu.first("ul").offset().top;
    
        //add class active
        $('.menu-concours li.link-anchor a').on('click', function (e) {
          e.preventDefault();
          $('.menu-concours li.link-anchor a').removeClass("active");
          $(this).addClass("active");
        });

        let menusConcours = function(){
          return fixedMenu.position().top + fixedMenu.height() + 40;
        };

        let stickyNav=function(){
          let stickyNavTop = navTop - menusConcours();
          let scrollTop = 0;
          scrollTop = $("html").scrollTop() || $("body").scrollTop();
         
          if(scrollTop > stickyNavTop ) {
              if(!navMenu.hasClass('sticky')) {
                  navMenu.addClass('sticky').next().css("top", navMenuHeight);
                  navMenuHeight = navMenu.height();
              }
              stickyNavTop = navTop - menusConcours();
              navMenu.css("top", menusConcours());
              if($(document).width() >= 992) {
               $(".link-concours").fadeOut(100);
              }else {
               $(".link-anchor").fadeOut(100);
              }
              
          } else if(scrollTop <= stickyNavTop && navMenu.hasClass('sticky')){
              navMenu.removeClass('sticky').css("top","initial").next().css("top", 0);
              navMenuHeight = navMenu.height();
              $(".link-concours").fadeIn(100);
              $(".link-anchor").fadeIn(100);
          }
        };

        let btnConcours=$('.btnConcours');
        let totalContainer = $('.navbar').outerHeight(true) + $('.jumbotron').outerHeight(true) + $('.top').outerHeight(true) + $('.intro').outerHeight(true);
        let menuheight = fixedMenu.position().top + fixedMenu.outerHeight(true) + navMenu.outerHeight(true);
        let totalDist = menuheight + totalContainer;
        
        if($(document).width() >= 1600) {
          totalDist = menuheight + totalContainer + 350;
        }
    
        let btnConcoursPos = function(){
          return fixedMenu.position().top + fixedMenu.outerHeight(true) + navMenu.height();
        };
        
        let stickyButton=function() {
          let scrollTop = 0;
          scrollTop = $("html").scrollTop() || $("body").scrollTop();
          if($(document).width() >= 992 && scrollTop > totalDist) {
              if(!btnConcours.hasClass('sticky')) {
                btnConcours.addClass('sticky');
              }
              
              btnConcours.css("top", btnConcoursPos());
          } else if(scrollTop <= totalDist && btnConcours.hasClass('sticky')){
            btnConcours.removeClass('sticky').css("top","initial");
          }

        }

        /*** Smooth scrolling when clicking an anchor link -  voir https://css-tricks.com/snippets/jquery/smooth-scrolling/  ***/
        $('.smoothScroll').on('click', function() {
          let totalHeight =  fixedMenu.outerHeight(true) + navMenu.outerHeight(true);
          if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
            let target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            if (target.length > 0) {
              $('html,body').animate({
                scrollTop: target.offset().top - totalHeight-30
              }, 1000);
              return false;
            }
          }
        });

        $(window).on('scroll', function(e){
          e.preventDefault();
          stickyNav();
          stickyButton();
        });
    }
  }

  /*
  * Page - Balados
  */

  var isBaladosPage = $('body').hasClass('balados') ? true : false;
  if(isBaladosPage) {
    //stiky Audio 
    if($('.audio-list').length) {
      let navAudio =$('.audio-wrapper');
      let navAudioHeight = navAudio.outerHeight(true);
      let fixedMenu = $("#main-navbar");
      let navTop = navAudio.first("audio").offset().top;
      // let totalHeight = navAudio.height() + fixedMenu.height();
     
      let audioBalados = function(){
        return fixedMenu.position().top + fixedMenu.height() + 40;
      };

      let stickyBalados = function() {
        let stickyNavTop = navTop - audioBalados();
        let scrollTop = 0;
        scrollTop = $("html").scrollTop() || $("body").scrollTop();
        if(scrollTop > stickyNavTop ) {
            if(!navAudio.hasClass('sticky')) {
                navAudio.addClass('sticky').next().css("margin-top", navAudioHeight);
                navAudioHeight = navAudio.height();
            }
            stickyNavTop = navTop - audioBalados();
            navAudio.css("top", audioBalados());
        } 
      };

   
      $('.playlist-balados').on('click', 'a', function(e){ 
        e.preventDefault();
        stickyBalados();
        // var elm = e.target;
        let audio = document.getElementById('audio-balado');
        audio.src = this.getAttribute('data-value');
        
        //set Title audio
        let seeTitle = document.getElementById('title-audio');
        let audioTitle = $(this).find('.title-balado').text();
        seeTitle.innerHTML = audioTitle;
        
        //add class audio-active to item
        $(this).closest('a.item').addClass("audio-active").siblings('a.item').removeClass('audio-active');
        $('nav').addClass("balado-active");
        
        audio.pause();
        audio.addEventListener("canplay", function onCanPlay() {
          audio.removeEventListener("canplay", onCanPlay);
          audio.play();
        });
        audio.load();

      });

      // close sticky navigation balados audio
      $('.audio-wrapper .btn-close').on('click', function(e){ 
        let audio = document.getElementById('audio-balado');
        audio.pause();
        audio.src = "";
        $('.playlist-balados .item').removeClass('audio-active');
        navAudio.removeClass('sticky').css("top","initial").next().css("margin-top", 0);
        navAudioHeight = navAudio.height();
        $('nav').removeClass("balado-active");
      });

     
    }

    // $('.playlist-balados-youtube li').on('click', function(e){ 
    //   e.preventDefault();
    //   var elm = e.target;
    //   var audio = document.getElementById('audio-balado');
    //   var source = document.getElementById('audioSourceBalado');
    //   // source.src = elm.getAttribute('data-value');
    //   videoID = elm.getAttribute('data-value');
    //   // Fetch video info (using a proxy to avoid CORS errors)
    //   //'https://cors-anywhere.herokuapp.com/'
    //   fetch("https://www.youtube.com/get_video_info?video_id=" + videoID, { mode: 'no-cors'}).then(response => {
        
    //     if (response.ok) {
          
    //       response.text().then(ytData => {
            
    //         // parse response to find audio info
    //         var ytData = parse_str(ytData);
    //         var getAdaptiveFormats = JSON.parse(ytData.player_response).streamingData.adaptiveFormats;
    //         var findAudioInfo = getAdaptiveFormats.findIndex(obj => obj.audioQuality);
            
    //         // get the URL for the audio file
    //         var audioURL = getAdaptiveFormats[findAudioInfo].url;
            
    //         // update the <audio> element src
    //         var youtubeAudio = document.getElementById('audio-balado');
    //         youtubeAudio.src = audioURL;
    //         //audio.src = audioURL;
    //         // console.log(audioURL);
            
    //       });
    //     }
    //   });

    //   function parse_str(str) {
    //     return str.split('&').reduce(function(params, param) {
    //       var paramSplit = param.split('=').map(function(value) {
    //         return decodeURIComponent(value.replace('+', ' '));
    //       });
    //       params[paramSplit[0]] = paramSplit[1];
    //       return params;
    //     }, {});
    //   }




    //   var isPlaying = false;
    //   // source.src = elm.getAttribute('data-value');
    //   if($('.playlist-balados-youtube li').hasClass("audio-active")) {
    //     $('.playlist-balados-youtube li').removeClass("audio-active");
    //   }
    //   $(this).addClass("audio-active");
    //   var audioPlay = audio.play();
    //   var audioPause = audio.pause();
    //   if(audioPlay !== undefined) {
    //     audio.pause();
    //   }
    //   audio.pause();
    //   audio.load(); //call this to just preload the audio without playing
    //   setTimeout(function() {
    //     isPlaying = true;
    //     audio.play();
    //   }, 0);
    // });

  

  }

})(jQuery);

